import React, { Component } from 'react'

import Header from '../Header/Header'
import Block from "../Block/Block"

import './About.css'

import Ivy from '../../assets/headshot/ivy-2.jpg'
import Cat from '../../assets/headshot/cat-2021.jpg'
import Peter from '../../assets/headshot/Peter.jpg'
import Goffy from '../../assets/headshot/AndrewGoff.jpg'
import Yuhan from '../../assets/headshot/Yuhan.jpg'
import Kite from '../../assets/headshot/Kite-edited.jpg'
import Nadia from '../../assets/headshot/Nadia-edited.jpg'
import HeaderImg from '../../assets/audio-1844798_1920.jpg'



/**
 *  About page component
 *  @version 1.0
 *  @author Nadia Mayangputri
 */ 
export default class About extends Component {
    
    render() {
        // Paragraph on header
        let headerContent =
            <div>
                <p>
                    Established in 2014, Sleigh Group grew from Synchresis, a dedicated localization company, into a global content company 
                    with a focus on audience engagement through cross cultural narratives and expert localization. With over forty years 
                    combined experience in the industry, Sleigh’s founders have built the company into a trusted provider servicing some 
                    of the biggest names in global entertainment.
                </p>
            </div>

        return(
            <div>
                <Header 
                    pageName="About us" 
                    bgColor="linear-gradient(145deg, #5680E9, #84CEEB) 0% 0% / 300% 300%" 
                    bgImage={HeaderImg}
                    title="Our Story"
                    content={headerContent}
                    marginTop="80px"
                    paddingBottom="150px"
                />
                
                <div className="main-container" id="about-container">
                    <div className="main-content">
                        <div className="grid-container">
                        <h1 style={{color: 'rgb(0,0,128)'}}>Our People</h1>
                            <div className="about-block">
                                <Block 
                                    title="Ivy Novacco"
                                    subtitle="Founder / CEO"
                                    image={Ivy}
                                    flexDirection= "row"
                                    content={
                                        <div>
                                            <p>
                                                After successful careers in Process Management, Process Development and 
                                                Cultural Training with Dell and HP, Ivy returned to her voice acting roots 
                                                to build Sleigh Group. She utilizes her financial expertise and business 
                                                acumen in conjunction with her 20 odd years of experience in the entertainment 
                                                industry to build the future of entertainment.
                                            </p>
                                        </div>
                                    }
                                />
                            </div>
                            
                            <div className="about-block">
                                <Block 
                                    title="Dr. Catherine Lopes"
                                    subtitle="Founder / CIO"
                                    image={Cat}
                                    flexDirection= "row-reverse"
                                    content={
                                        <div>
                                            <p>
                                                With more than two decades of experience in data &amp; analytics, Dr. Lopes brings broad expertise 
                                                and experience in both technology and data analytics to Sleigh Group. She has worked in America, 
                                                Australia and China in both academia and industry across consulting, software development and technology 
                                                and data delivery. For the last decade, she has focused on helping organizations bridge the gap between 
                                                business and technology by using data governance and management to enable machine and artificial intelligence. 
                                                At Sleigh Group, Dr. Lopes spearheads the efforts in applying technological solutions to the entertainment production 
                                                industry’s pain points.
                                            </p>
                                        </div>
                                    }
                                />
                            </div>

                            <div className="about-block">
                                <Block 
                                    title="Peter Novacco"
                                    subtitle="Founder / Director"
                                    image={Peter}
                                    flexDirection= "row"
                                    content={
                                        <div>
                                            <p>
                                                If you took all of Peter’s qualifications and stacked them one on top of the other, his pile would be significantly 
                                                taller than most, and he spends most of his time at Sleigh Group making sure that everyone else has the support they 
                                                need to shine. Peter brings over 20 years of experience in training and development, and is a long-term Defence member. 
                                                His contributions include Defence Assistance to the Civil Community tasks, such as assisting with bushfire relief.
                                            </p>
                                        </div>
                                    }
                                />
                            </div>

                            <div className="about-block">
                                <Block 
                                    title="Andrew Goff"
                                    subtitle="CFO"
                                    image={Goffy}
                                    flexDirection= "row-reverse"
                                    content={
                                        <div>
                                            <p>
                                                Andrew’s passion for businesses which are sustainable and purposeful as well as profitable 
                                                made him a good fit for Sleigh Group. His professional contributions to causes like marriage 
                                                equality, ending modern slavery and anti-racism makes him a great one. Previously Andrew has 
                                                worked in scale-ups and “intrapreneurship”, helping to launch .blog, contribute to the success 
                                                of partnership programs in financial services, and working on data analytics innovations that 
                                                led to self-install broadband internet in Australia. His experience in remote work 
                                                environments—most notably for Automattic—has helped Sleigh Group navigate the challenges of 
                                                COVID-19 and ready us to seize the global opportunities ahead.
                                            </p>
                                        </div>
                                    }
                                />
                            </div>

                            <div className="about-block">
                                <Block 
                                    title="Kite Evangelista"
                                    subtitle="Business Development Manager"
                                    image={Kite}
                                    flexDirection= "row"
                                    content={
                                        <div>
                                            <p>
                                                With over a decade in learning and development, customer service and project management, 
                                                Kite appreciates clarity, efficiency, and bringing projects in on time and on budget. 
                                                She has worked for Ericsson, Nokia, and Source-one Asia to name just a few and has a no-nonsense 
                                                approach to resolving issues, focusing on Strategic Thinking and Relationship Building.
                                            </p>
                                            <p>
                                                A veteran of the start up scene, Kite has built the foundations for Link2Support Davao, 
                                                Afni Philippines, and Quantrics Taytay which are still going strong today. While at Sleigh Group, 
                                                Kite hopes to further her love of photography and animation.
                                            </p>
                                        </div>
                                    }
                                />
                            </div>

                            <div className="about-block">
                                <Block 
                                    title="Yuhan Lim"
                                    subtitle="Head of Original Content"
                                    image={Yuhan}
                                    flexDirection= "row-reverse"
                                    content={
                                        <div>
                                            <p>
                                                Coming from a family of accountants and financial managers, Yuhan obviously went into the arts, 
                                                and has been publishing queer fiction and running various artistic events for the past ten years 
                                                under the name Matthew Lang. He brings his understanding of narrative, the artistic process, 
                                                and event management to Sleigh Group and credits his ability to translate between the languages 
                                                of ‘Creative’ and ‘Business’ as one of his most valuable skills.
                                            </p>
                                            <p>
                                                When not writing books or developing scripts, Yuhan designs and produces size inclusive womenswear 
                                                with pockets for the aptly named Quokka Pockets.
                                            </p>
                                        </div>
                                    }
                                />
                            </div>

                            <div className="about-block">
                                <Block 
                                    title="Nadia Mayangputri"
                                    subtitle="Web Developer"
                                    image={Nadia}
                                    flexDirection= "row"
                                    content={
                                        <div>
                                            <p>
                                                Nadia’s love of digital platforms began at the University of Melbourne, when one of her designs received 
                                                an Honourable Mention from the Interaction design Lab there. Nadia is passionate in bringing ideas to life 
                                                and creating exceptional user experiences for Sleigh Group’s digital platforms. While she began in front-end 
                                                development, she has continued to grow her skills in back-end development, database management, and cloud web services.
                                            </p>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}