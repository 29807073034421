import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import About from "../components/About/About"


const TeamPage = () => (
  <Layout>
    <SEO 
      title="About us" 
      keywords="Ivy Novacco, Cat Lopes, Ivy Baldonado, Catherine Lopes, Dr Catherine Lopes, Doctor Catherine Lopes, localization, localisation, language services, Andrew Goff, Peter Novacco"/>
    <About />
  </Layout>
)

export default TeamPage
